<template>
  <div id="myModal" class="items-center justify-center overflow-hidden" :class="`modal ${bgopacity} bg-grey-light ${fullscreen ? 'w-screen h-screen fullscreenmodal' : ''}`" ref="modal-content" >
    <div :class="`bg-cover bg-center ${!fullscreen ? 'modal-content' : ''} ${width} ${height}  ${rounded ? `rounded-lg`:''}`" :style="customBg">
      <div :class="`${bgColor} bg-cover  bg-opacity-95 h-full flex items-center justify-center  ${rounded ? `rounded-lg`:''}`">
        <div class="container h-full flex justify-center items-center">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  emits: ["action"],
  mounted() {
    if(this.open){
      this.openModal()
    }
  },
  methods: {
    openModal() {
      this.$refs["modal-content"].style.display="flex";
    },
    cancel() {
      this.$emit('action', 'cancel')
      this.$refs["modal-content"].style.display="none";
    },
    yes() {
      this.$refs["modal-content"].style.display="none";
      this.$emit('action', 'disconnect')
    }
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: true
    },
    bgImage: {
      type: String,
      default: undefined
    },
    bgColor: {
      type: String,
      default: 'false'
    },
    width: {
      type: String,
      default: 'auto'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    bgopacity: {
      type: String,
      default: undefined
    },
    noBg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      currentTheme: state => {
        return state.currentTheme
      },
      enquete: state => state.enquete.enquete,
      customBg() {
        if (this.noBg){
          return "";
        }
        if (this.enquete.thumbAndBgPic === "assets/pics/buildings.jpg"){
          return {
            'background-image':`url(`+require(`../assets/pics/buildings.jpg`),
          }
        }
        return {
          'background-image':`url(${this.enquete.thumbAndBgPic})`,
        }
      },
    }),
  },
  watch: {
    open(val) {
      val ? this.openModal() : this.cancel();
     }
  },
}
</script>

<style lang="scss" scoped>
/* Add padding and center-align text to the container */
.container {
  padding: 16px;
  text-align: center;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
}
/* Modal Content/Box */
.modal-content {
  box-shadow: 0px 0px 20px 5px gray;
}
/* Style the horizontal ruler */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
#myModal{
  z-index:2;
  overflow:hidden;
}
/* Change styles for cancel button and delete button on extra small screens */
@media screen and (max-width: 300px) {
  .cancelbtn, .deletebtn {
    width: 100%;
  }
}
</style>
