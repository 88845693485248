<template>
  <nav class="bg-midnight-blue flex items-center text-yellow-light h-[95px]  font-bariol font-bold">
    <ul class="flex mr-auto">
      <li class="h-[91px] pt-[24px] grand-text-bold  pl-10 pr-10">
          {{$t.header.bouton_COPROXI}}
      </li>
      <li class="h-[91px] pt-[30px] pl-10 pr-10">
        <router-link :to="{ name: 'Home', params: {'bypass' : 'true'} }">
          <span class="font-normal" v-if="!firstLevelFinished">{{$t.header.bouton_niveau1}}</span>
          <span class="font-normal" v-else>{{$t.header.bouton_niveau1_retry}}</span>
        </router-link>
        <span v-if="firstLevelFinished">
          <img src="../assets/icons/icon-chevron.svg" alt="icon">
        </span>
        <router-link v-if="firstLevelFinished" :to="{ name: 'enquete/EnqueteWrapper' }">
          <span class="font-bold">{{$t.header.bouton_niveau2}}</span>
        </router-link>
      </li>
    </ul>
    <ul class="flex justify-end w-[590px] text-center  ">
      <router-link to="/doc" @click="click('doc')" :class="[docclickedVal ? ' text-yellow-light  border-b-4' : 'text-blue-grey ease-in-out border-b-4', 'transparent-border animated']">
        <li class="h-[91px] pt-[30px] pl-10 pr-10">
          <img src="../assets/icons/ico-bibli.svg" v-if="docclickedVal">
          <img src="../assets/icons/ico-bibli-dark.svg" v-if="!docclickedVal">
          {{$t.header.bouton_Documentation}}
        </li>
      </router-link>
      <router-link to="/help" @click="click('help')" :class="[helpclickedVal ? 'text-yellow-light border-b-4' : 'text-blue-grey  ease-in-out border-b-4', ' transparent-border animated']">
        <li class="h-[91px] pt-[30px] pl-10 pr-10 ">
          <img src="../assets/icons/icon-aide.svg" v-if="helpclickedVal">
          <img src="../assets/icons/icon-aide-dark.svg" v-if="!helpclickedVal">
          {{$t.header.bouton_Aide}}
        </li>
      </router-link>
        <li @click="click(''); modalopen=true" class="h-[91px] pt-[30px] pl-10 pr-10 transparent-border border-b-4 cursor-pointer">
          <img src="../assets/icons/icon-logout.svg">
          {{$t.header.bouton_Compte}}
        </li>
    </ul>
  </nav>
  <SimpleModal class="z-3" :open="modalopen" @action="getModalData($event)"></SimpleModal>
</template>

<script>
import SimpleModal from "./SimpleModal";
import { useAuth } from '@/composables/auth';
import { IsFirstLevelFinished } from '@/composables/user_log';

export default {
  name: "HeaderCoproxi",
  components: {SimpleModal},
  data() {
    return {
      modalopen: false,
      helpclickedVal: true,
      docclickedVal: true,
      clickedVal: false,
    }
  },
  methods: {
    getModalData(val) {
      this.modalopen = false;
      if(val ==="disconnect"){
        const { logout } = useAuth();
        logout();
      }
    },
    click(val){
      if(val === 'doc'){
        this.helpclickedVal = false
        this.docclickedVal = true
      }else if(val === 'help'){
        this.helpclickedVal = true
        this.docclickedVal = false
      }else{
        this.helpclickedVal = this.docclickedVal = true
      }
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    firstLevelFinished() {
      return IsFirstLevelFinished()
    },
  },
  mounted() {
    if(this.currentRouteName.toLowerCase() === 'documentation'){
      this.click('doc')
    }else if(this.currentRouteName.toLowerCase() === 'help'){
      this.click('help')
    }else{
      this.click()
    }
  }

}
</script>

<style scoped>
.animated.router-link-active{
  @apply text-yellow-light transition duration-300 ease-in-out border-b-4 border-yellow-light
}
li{
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.animated{
}
.transparent-border{
  /*bug in tailwind */
  border-color:transparent;
}
svg path{
  fill: red;
}
img{
  display: inline;
}
</style>
