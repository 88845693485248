<template>
  <div :class="`z-0 absolute w-screen h-screen flex text-yellow-light bg-opacity-90 justify-start  items-center  ${currentTheme}`">
    <div class="z-0 absolute w-screen h-screen flex flex-col align-center text-yellow-light justify-center items-center">
      <div class=" h-1/5"></div>
        <div class="text-4xl z-0 font-bold flex flex-col justify-center flex-grow text-left narrative-content" style="max-width: 1100px">
          <nl2br tag="p" class="font-bariol" :text="narrativeDom?.content"/>
          <audio ref="audio-player" style="display: none" v-if="narrativeDom?.audio">
            <source :src="mediaserverurl+narrativeDom?.audio" type = "audio/wav" />
            Your browser does not support the audio element.
          </audio>
        </div>
      <div class="flex flex-row items-start justify-between w-10/12 h-1/5">
        <div class="flex items-center w-4/12 self-center">
          <div class="pr-10 " v-if="narrativeDom?.audio">
            <img class="w-7 h-14" src="@/assets/icons/icon-wave.svg">
          </div>
          <ShadowButton v-if="narrativeDom?.audio" :disabled="isPlaying" @click="isPlaying = true; startAudio()" :theme="`${currentTheme}-light-yellow`">{{$t.buttons.bouton_listenagain}}</ShadowButton>
        </div>
         <div class="w-4/12  flex w-all jus self-center justify-center">
          <ShadowButton :theme="`light-yellow-${currentTheme}`" @click="$emit('nextstep', {action: 'next', transition: 'up'})">{{$t.buttons.bouton_continuer}}</ShadowButton>
        </div>
        <div class="w-4/12"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ShadowButton from "../../components/ShadowButton";
import {mapState} from "vuex";


export default {
  emits: ["nextstep"],
  components: {
     ShadowButton
  },
  mounted() {
      this.init()
    // console.log('NARRATIVE', this.narrativeDom)
    },
    computed: mapState({
       currentTheme: state => state.currentTheme
    }),
  data() {
    return {
      'mediaserverurl': process.env.VUE_APP_MEDIA_URL,
      'audioLoaded': false,
      'isPlaying': false,
      'narrativeDom': this.narrative
    }
  },
  props: {
    'narrative': Object
  },
  methods: {
    init(){
      if(this.narrative.audio){

        this.$nextTick(() => {
          var audio = this.$refs['audio-player'];
          audio.addEventListener(
              "canplay",
              function() {
                this.audioLoaded=true;
                audio.play()
                this.isPlaying = true;
              }.bind(this)
          );

          this.$watch("isPlaying",() => {
            if(this.isPlaying) {
              var audio=this.$refs['audio-player'];
              if(!this.listenerActive) {
                this.listenerActive=true;
                audio.addEventListener("timeupdate",this.playBackListeners);
              }
            }
          });
        });
      }
    },
    playBackListeners() {
      const audio = this.$refs["audio-player"];
      //Annoying error
      if (audio != null){
        audio.addEventListener("ended", this.audioendListener);
      }
    },
    audioendListener() {
      this.isPlaying = false;
      this.cleanupListeners();
    },
    startAudio() {
      this.isPlaying = true;
      const audio = this.$refs["audio-player"];
      audio.play();
    },
    cleanupListeners() {
      const audio = this.$refs["audio-player"];
      audio.removeEventListener("timeupdate", this.playbackListener);
      audio.removeEventListener("ended", this.audioendListener);
    },
  },
  watch:{
    narrative(val){
      this.narrativeDom = undefined

      this.$nextTick(() => {
        // console.log('TICK')
        this.narrativeDom = val
        this.init()
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.narrative-content{
  @screen 1480{
    @apply p-0
  }
  padding: 0 15vmin;
}
</style>
